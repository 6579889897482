import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useSocialSharingForm(props) {
  const loading = ref(false)
  const formData = ref({})

  const fetchData = () => {
    loading.value = true
    store.dispatch('social-sharing-form/fetchData', props)
      .then(response => {
        const { data } = response.data
        formData.value = data
        loading.value = false
      })
      .catch(() => {
        formData.value = {}
        loading.value = false
      })
  }

  return {
    loading,
    formData,
    fetchData,
  }
}
