        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="6"
                                        >
                            <social-sharing-form
  sendApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/lp/add/invite"
  getApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/lp/item/invite"
  :networks="[{&quot;title&quot;:&quot;&quot;,&quot;description&quot;:&quot;&quot;,&quot;url&quot;:&quot;&quot;,&quot;network&quot;:&quot;vk&quot;,&quot;variant&quot;:&quot;vk&quot;,&quot;icon&quot;:&quot;fab fa-vk&quot;},{&quot;title&quot;:&quot;&quot;,&quot;description&quot;:&quot;&quot;,&quot;url&quot;:&quot;&quot;,&quot;network&quot;:&quot;telegram&quot;,&quot;variant&quot;:&quot;telegram&quot;,&quot;icon&quot;:&quot;fab fa-telegram&quot;},{&quot;title&quot;:&quot;&quot;,&quot;description&quot;:&quot;&quot;,&quot;url&quot;:&quot;&quot;,&quot;network&quot;:&quot;whatsapp&quot;,&quot;variant&quot;:&quot;whatsapp&quot;,&quot;icon&quot;:&quot;fab fa-whatsapp&quot;}]"
/>            </b-col>
                                        <b-col
                                                                                      sm="12"
                                            class="mt-2"
                          >
                            <b-button
      v-b-modal.sp-modal-form
          variant="outline-primary"
    >Зарегистрировать новичка</b-button>            </b-col>
                                        <b-col
                                                                                                >
                            <sp-modal-form
            sendApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/contract/add"
            method="post"
            getApi=""
            id="sp-modal-form"
            title="Зарегистрировать новичка"
    />
            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import SocialSharingForm from '@core/components/social-sharing-form/SocialSharingForm.vue'
                                                              import {
            BButton,
          } from 'bootstrap-vue'
                                                              import SpModalForm from '@core/components/sp/sp-modal-form/SpModalForm.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SocialSharingForm,
                                                                              BButton,
                                                                              SpModalForm,
                                          },
  setup() {
    
      },
}
</script>
    