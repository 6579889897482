import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchData(context, payload) {
      const url = payload.apiId ? `${payload.getApi}/${payload.apiId}` : payload.getApi
      return axios.get(url)
    },
  },
}
