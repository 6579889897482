<template>
  <div>
    <div class="relative">
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-form-group
        label="Отправить приглашение в мессенджер"
        label-for="social-sharing-form"
      >
        <b-form-textarea
          id="social-sharing-form"
          v-model="formData.inviteTmp"
          trim
          rows="3"
        />
      </b-form-group>
      <div
        class="d-flex mt-1"
      >
        <ShareNetwork
          v-for="(networkItem, key) in networks"
          :key="key"
          :network="networkItem.network"
          :url="formData.linkTmp || networkItem.url || ''"
          :title="networkItem.title"
          :description="formData.inviteTmp || networkItem.description || ''"
          class="d-block mr-50 mr-sm-1"
        >
          <b-button
            :variant="networkItem.variant"
            href="javascript:void(0)"
            size="sm"
          >
            <i
              :class="networkItem.icon"
              style="font-size: 24px"
            />
          </b-button>
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BFormGroup,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import socialSharingFormStoreModule from './socialSharingFormStoreModule'
import useSocialSharingForm from './useSocialSharingForm'
import store from '@/store'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    BOverlay,
  },
  props: {
    sendApi: {
      type: String,
      required: true,
    },
    getApi: {
      type: String,
      required: true,
    },
    networks: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'social-sharing-form'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, socialSharingFormStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      loading,
      formData,
      fetchData,
    } = useSocialSharingForm(props)

    if (props.getApi) {
      fetchData()
    }

    return {
      loading,
      formData,
    }
  },
}
</script>
